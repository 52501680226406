import React from "react";
import { graphql } from "gatsby";
import { ProductListingPage } from "../components/product-listing";

export default function Shop (props) {
	return <ProductListingPage {...props} />
}

export const query = graphql`
  query {
		collection: shopifyCollection(handle: { eq: "everything" }) {
			...CollectionPage
		}
  }
`

export { CollectionMeta as Head } from "../components/meta"